import EarlyBookingDiscount from '@/components/home/early-booking-discount';
import Image from 'next/image';
import { isAdacCustomer } from '@/lib/adac';

export default function IndexHero({ withDiscounts = false }) {
  return (
    <div
      id="header-image"
      className="h-[300px] lg:h-[500px] mb-[280px] md:mb-[100px] lg:mb-[50px]"
    >
      {isAdacCustomer() && (
        <div className="h-full w-full shadow-box flex justify-center items-center">
          <div className="flex lg:flex-col gap-5 items-center justify-between lg:justify-center">
            <span className="text-2xl lg:text-center text-white">In Kooperation mit dem</span>
            <Image
              title="ADAC Partner"
              alt="ADAC Partner"
              src="/images/adac.svg"
              objectFit="contain"
              height={150}
              width={150}
            />
          </div>
        </div>
      )}
      {(withDiscounts && !isAdacCustomer()) && (
        <div className="flex justify-center w-full h-full shadow-box">
          <EarlyBookingDiscount />
        </div>
      )}
    </div>
  );
}
