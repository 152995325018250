import { SingleDatePicker } from 'react-dates';
import { useEffect, useState } from 'react';
import moment from 'moment';
import InputWrapper from '../globals/input-wrapper';
import CalendarIcon from '../../../public/assets/icons/anker/calendar.svg';

export const HORIZONTAL_ORIENTATION = 'horizontal';

export default function DatePicker({ inputDate, setValue, onDateChange }) {
  const [date, setDate] = useState(inputDate ? moment(inputDate) : null);
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => () => setDate(null), []);

  return (
    <div>
      <InputWrapper icon={(
        <CalendarIcon
          className="absolute right-0 z-10 w-5 h-6 pointer-events-none fill-action top-3 left-2"
        />
      )}
      >
        <SingleDatePicker
          date={date}
          focused={focusedInput}
          firstDayOfWeek={1}
          id="date-range-picker-id"
          placeholder="Früheste Abfahrt"
          hideKeyboardShortcutsPanel
          readOnly
          phrases={{ closeDatePicker: 'Close', clearDates: 'Clear' }}
          displayFormat="DD. MMMM YYYY"
          numberOfMonths={1}
          orientation={HORIZONTAL_ORIENTATION}
          onDateChange={(date) => {
            setValue('date_from', date ? date.format('YYYY-MM-DD') : '');
            setDate(date);
            onDateChange(date);
          }}
          onFocusChange={({ focused }) => setFocusedInput(focused)}
        />
      </InputWrapper>
    </div>
  );
}
