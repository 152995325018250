// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';
import { Navigation } from 'swiper';
import Image from 'next/image';
import Link from 'next/link';

export default function SwiperOfferSlider({ items }) {
  return (
    <div>
      {items && items.length > 0 && (
        <Swiper
          slidesPerView={3}
          spaceBetween={10}
          loop
          breakpoints={{
            '@0.1': {
              slidesPerView: 1,
            },
            '@0.75': {
              slidesPerView: 2,
            },
            '@1.00': {
              slidesPerView: 3,
            },
          }}
          modules={[Navigation]}
          navigation
          className="w-full"
        >
          {items.map((item, k) => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {item.thumbnail.length > 0 && (
              <SwiperSlide key={k}>
                <Link href={item.link}>
                  <a>
                    <div className="relative group h-[250px]">
                      <Image
                        src={item.thumbnail[0].publicUrl}
                        alt={item.thumbnail[0]?.properties?.alternative ?? ''}
                        layout="fill"
                        objectFit="cover"
                      />
                      <div
                        className="absolute text-primary z-20 bg-action group-hover:bg-primary group-hover:text-action right-0 bottom-0 px-3 py-2"
                      >
                        <div
                          className="cms-offer-text"
                          dangerouslySetInnerHTML={{ __html: item.offer }}
                        />
                      </div>
                    </div>
                  </a>
                </Link>
              </SwiperSlide>
              )}
            </>
          ))}
        </Swiper>
      )}
    </div>
  );
}
