import Link from '../../helper/link';

export default function UpsFeature({
  children,
  title,
  description = '',
  className = '',
  variant = 'action',
  href = '',
  label = '',
  full = false,
}) {
  return (
    <div className="h-full overflow-hidden">
      <div
        className={`transition-all duration-500 hover:scale-[102%] ${!full ? 'px-7 py-3' : ''} items-center flex ${variant === 'action' ? 'bg-primary-300 text-white ' : 'bg-action text-primary'} ${className}`}
      >
        <div className="w-1/3 md:w-1/5 text-action flex justify-center">{children}</div>
        {title !== ''
        && <span className="ml-2 md:ml-5 block md:text-lg text-center uppercase font-semibold">{title}</span>}
        {href && label && (
          <Link href={href}>
            <a
              className={`w-full ml-3 md:ml-0 text-center py-2 uppercase font-bold ${variant === 'action' ? 'bg-action text-primary' : 'bg-primary text-white'} ${className}`}
            >
              {label}
            </a>
          </Link>
        )}
      </div>
      {description !== ''
      && <div className="bg-gray-100 text-lg p-3 h-full">{description}</div>}
    </div>
  );
}
