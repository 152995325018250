import { ChevronRightIcon } from '@heroicons/react/outline';
import { useContext } from 'react';

import { DispatchStateContext, GlobalStateContext } from '@/lib/app-server-context';

import Link from '@/components/helper/link';
import ContentHeadline from '../content/headline';
import UpsFeature from './ups/feature';
import CartIcon from '../../../public/assets/icons/f_cart.svg';
import BeraterIcon from '../../../public/assets/icons/Berater.svg';
import DiffIcon from '../../../public/assets/icons/Vergleichangebot.svg';
import PhoneIcon from '../../../public/assets/icons/f_phone2.svg';
import appConfig from '../../../app.config';

export default function BookingOptions() {
  const useGlobalState = () => [
    useContext(GlobalStateContext),
    useContext(DispatchStateContext),
  ];

  const [, dispatch] = useGlobalState();

  return (
    <div className="pt-8 pb-4 container px-4 2xl:px-0">
      <ContentHeadline tag="h2" before="Ihre Buchungs" className="mb-2">
        möglichkeiten bei
        CharterCheck
      </ContentHeadline>
      <div className="">
        <div
          className="mt-8 grid grid-flow-row md:grid-cols-2 gap-5 text-primary-500 md:grid-cols-3"
        >
          <a href="search">
            <UpsFeature
              title="Online buchen"
              href={appConfig.primarySearchPage}
              description={(
                <div className="flex justify-center items-center">
                  Selbst suchen und online buchen

                  <ChevronRightIcon className="h-6 text-action" />
                </div>
              )}
            >
              <CartIcon
                className="w-full h-16 fill-action"
              />
            </UpsFeature>
          </a>
          <UpsFeature
            title="telefonische Beratung"
            description={(
              <span>
                Lassen Sie sich individuell
                beraten:
                <br />
                <a
                  href={`tel:${appConfig.phone}`}
                  className="font-bold"
                >
                  {appConfig.phone}
                </a>
                von Mo-Fr. 9-12 und 14-18 Uhr
              </span>
            )}
          >
            <PhoneIcon
              className="w-full h-16 fill-action"
            />
          </UpsFeature>
          <Link href="/kontakt">
            <a>
              <UpsFeature
                title="Persönliches Angebot"
                description={(
                  <>
                    Schreiben Sie uns Ihre
                    Wünsche und erhalten
                    Sie innerhalb von 2h ein
                    persönlichen Angebot
                  </>
                )}
              >
                <DiffIcon
                  className="w-full h-16 fill-action"
                />
              </UpsFeature>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}
