import Image from 'next/image';
import style from '@/styles/services.module.css';
import Link from '@/components/helper/link';
import ContentHeadline from '../content/headline';

export default function TopDestinations({ items = [] }) {
  const classMap = [
    'lg:row-span-3 lg:col-span-2',
    '',
    'lg:row-span-2',
    '',
    '',
    'lg:row-span-2',
    'lg:col-span-2',
  ];
  const a = items.slice(0, 7);
  return (
    <section className="pt-4 pb-8">
      <div className="max-w-7xl mx-auto w-full px-6 xl:px-0">
        <ContentHeadline tag="h2" after="Reiseziele" className="mb-4 mt-4">Beliebteste</ContentHeadline>
        <div className="flex flex-col space-y-4 text-white">
          <ul
            className="grid grid-flow-col lg:grid-flow-row grid-rows-1 lg:grid-rows-3 lg:grid-cols-5 gap-4 lg:gap-0.5 overflow-y-hidden overflow-x-auto lg:overflow-hidden lg:h-[500px]"
          >
            {a.map((item, k) => (
              <li
                key={k}
                className={`${classMap[k]} w-slider-item h-slider-item lg:w-full lg:h-full text-left overflow-hidden `}
              >
                <Link href={item.href}>
                  <a
                    className="relative font-bold flex flex-col items-center justify-end h-full duration-500 ease-in-out transition-spacing group"
                  >
                    <div
                      className="pb-10 "
                    >
                      <div
                        className={`relative  ${style.imageWrapper}`}
                      >
                        <Image
                          alt={item.name}
                          title={item.name}
                          src={item.image}
                          layout="fill"
                          objectFit="cover"
                        />
                      </div>
                    </div>
                    <div
                      className="absolute text-primary z-20 bg-action group-hover:shadow-lg left-0 bottom-0 transition-all px-3 py-2 duration-500 ease-in-out text-center lg:text-left "
                    >
                      <span className="text-lg block mb-0 leading-5">{item.name}</span>
                      <span
                        className="text-sm font-normal"
                      >
                        {item.subtext}
                      </span>
                    </div>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
