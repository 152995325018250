import config from '../../../app.config';
import serialize from './serialize';

const parse = (photo, params) => {
  const query = params || {};

  if (typeof photo === 'undefined' || !photo) {
    return '';
  }

  let p = photo;

  if (photo.indexOf('yachtsys.com') !== -1) {
    p = photo.replace('https://media.yachtsys.com', '/yachtsys');
  } else if (photo.indexOf('booking-manager.com') !== -1) {
    p = photo.replace('https://www.booking-manager.com', '/mmk');
  } else if (photo.indexOf('ws.nausys.com') !== -1) {
    p = photo.replace('https://ws.nausys.com', '/nausys');
  } else if (photo.indexOf('sednasystem.com') !== -1) {
    p = photo.replace('https://api1.sednasystem.com', '/sedna');
  } else if (photo.indexOf('api.chartercheck.com/fileadmin') !== -1) {
    p = photo.replace('https://api.chartercheck.com/fileadmin', '/fileadmin');

    if (!query.w) {
      query.w = 1500;
    }
  }

  if (/^(f|ht)tps?:\/\//i.test(p) && !/connect\.5-anker\./i.test(p)) {
    return p;
  }

  if (/^(f|ht)tps?:\/\//i.test(p) && /connect\.5-anker\./i.test(p)) {
    return `${p}?${serialize(params)}`;
  }

  return `${config.endpoint + p}?${serialize(query)}`;
};

export default parse;
