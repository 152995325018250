import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import parse from '@/components/helper/image';

export default function SEO({
  description = '',
  author = 'Chartercheck',
  meta = [],
  title = 'Chartercheck',
  canonicalUrl = '',
  image = '',
  robots = 'index, follow',
}) {
  const router = useRouter();
  const pageImage = image ? parse(image.publicUrl) : '';

  let canonicalUrlGen = canonicalUrl || router.asPath.split('?')[0];

  // prepend the base url to the canonical url
  if (canonicalUrlGen.indexOf('http') !== 0) {
    // remove first slash if it exists
    canonicalUrlGen = canonicalUrlGen.replace(/^\//, '');
    canonicalUrlGen = `${process.env.NEXT_PUBLIC_SITE_URL}/${canonicalUrlGen}`;
  }

  const metaData = [
    {
      name: 'robots',
      content: robots,
    },
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:site_name',
      content: 'Chartercheck',
    },
    {
      property: 'og:locale',
      content: router.locale,
    },
    {
      property: 'og:author',
      content: author,
    },
    {
      property: 'og:publisher',
      content: author,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:url',
      content: router.asPath,
    },
    {
      property: 'og:image',
      content: pageImage,
    },
  ].filter((i) => i.content).concat(meta);
  return (
    <Head>
      <title>{title}</title>
      <link rel="canonical" href={canonicalUrlGen} />
      {metaData.map((props, k) => (
        <meta
          key={`meta-${k}`}
          {...props}
        />
      ))}

      {pageImage && (
        <link href={pageImage} />
      )}
    </Head>
  );
}

SEO.defaultProps = {
  meta: [],
  description: '',
};
