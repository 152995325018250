export default function UpsFeatureRight({
  children, title, description, className = '',
}) {
  return (
    <div
      className={`transition cursor-pointer bg-action-400 hover:bg-action-500 text-primary px-7 py-3 flex-col items-center text-center justify-end flex ${className}`}
    >
      <span className="mt-3 block text-center uppercase font-bold text-lg leading-5 mb-3">{title}</span>
      <p>{description}</p>
      {children}
    </div>
  );
}
