import { useState } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import SEO from '@/components/helper/seo';
import TopFeatures from '@/components/home/top-ups';
import TopDestinations from '@/components/home/destinations';
import ContentHeadline from '@/components/content/headline';
import SearchLine from '@/components/content/search-line';
import { fetchAPI as typo3Api } from '@/lib/typo3';
import SwiperOfferSlider from '@/components/content/swiper-offer-slider';
import IndexHero from '@/components/home/index-hero';
import { isAdacCustomer } from '@/lib/adac';
import appConfig from '../../app.config';

export const getServerSideProps = async ({ locale }) => {
  const [res] = await Promise.all([
    await typo3Api(''),
  ]);

  return {
    props: {
      page: res,

      ...await serverSideTranslations(locale, ['boat', 'common', 'footer']),
    },
  };
};

export default function Home({ page }) {
  const defaultValues = {
    sd: '',
    country: '',
    region_id: '',
    location_id: '',
    duration: 7,
    adac: 0,
    boat_type_id: 'in:1,8,15,20,16,31',
    channel: appConfig.channel,
  };

  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState(null);
  const [locationId, setLocationId] = useState(defaultValues.location_id);
  const [sd, setSd] = useState('');
  const [boatTypeId, setBoatTypeId] = useState(defaultValues.boat_type_id);

  const offerSlider = () => {
    const elementIndex = page.content.colPos0.findIndex((e) => e.type === 'menu_card_list');
    if (elementIndex !== -1
      && page.content.colPos0[elementIndex].appearance.frameLayout === 'menu-cards-offer-slider'
    ) {
      return page.content.colPos0[elementIndex]?.content?.items;
    }
    return null;
  };

  return (
    <div>
      <SEO title={page.meta.title} description={page.meta.description} image={page.meta.thumbnail} />

      <div className="bg-gray-200 relative mt-header">
        <IndexHero />
        <div className="absolute w-full">
          <div className="max-w-7xl mx-auto w-full relative top-[-350px] md:top-[-190px] lg:top-[-140px]">
            <section>
              <div className="mx-auto w-full">
                <SearchLine
                  sd={sd}
                  country={country}
                  region={region}
                  locationId={locationId}
                  boatTypeId={boatTypeId}
                  setCountry={setCountry}
                  setRegion={setRegion}
                  setLocationId={setLocationId}
                  setSd={setSd}
                  setBoatTypeId={setBoatTypeId}
                  headline="Finden Sie Ihre perfekte Yacht"
                  adacDefault={isAdacCustomer() ? 1 : 0}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
      <TopFeatures />
      <TopDestinations items={page.options.destinations} />
      {offerSlider() !== null && (
        <section className="pt-4 pb-8 max-w-7xl mx-auto px-6 py-6 lg:py-4 xl:px-0">
          <header>
            <ContentHeadline tag="h2" after="Angebote" className="mb-2">Top</ContentHeadline>
          </header>
          <SwiperOfferSlider items={offerSlider()} />
        </section>
      )}
    </div>
  );
}
