import { Controller, useForm } from 'react-hook-form';
import { Popover } from '@headlessui/react';
import Dropdown from '../../elements/dropdown';
import InputWrapper from '../../globals/input-wrapper';

import CalendarIcon from '../../../../public/assets/icons/anker/calendar.svg';

export default function SearchFormDuration({ value, setValue, ...rest }) {
  const {
    control, watch,
  } = useForm({
    defaultValues: {
      duration: value,
    },
  });

  watch((v) => {
    setValue('duration', v.duration);

    // @todo workaround, close popover correctly
    document.getElementById('duration-apply-button').click();
  });

  const options = [
    {
      label: '1 Woche',
      value: 7,
    },
    {
      label: '2 Wochen',
      value: 14,
    },
    {
      label: '3 Wochen',
      value: 21,
    },
    {
      label: '4 Wochen',
      value: 28,
    },
    {
      label: 'Wochenende',
      value: 'weekend',
    },
    {
      label: 'Kurzwoche (3-6 Nächte)',
      value: 'btw:3,6',
    },
    {
      label: 'Verlängerte Woche (8-13 Nächte)',
      value: 'btw:8,13',
    },
  ];

  const getLabelForValue = (value) => {
    const selected = options.find((item) => String(item.value) === String(value));
    if (selected) {
      return selected.label;
    }
    return options[0].label;
  };

  return (
    <div data-cy="duration">
      <InputWrapper icon={(
        <CalendarIcon
          className="absolute right-0 z-10 w-5 h-6 -mt-px pointer-events-none fill-action top-3 left-2"
        />
    )}
      >
        {' '}
        <Controller
          control={control}
          name="duration"
          render={({
            field: {
              onChange, value,
            },
          }) => (
            <Dropdown value={`${getLabelForValue(value)}`} {...rest}>
              <div
                className="flex flex-row items-center justify-between w-full px-5 pt-2 pb-1 border-b border-gray-100 lg:border-none"
                data-cy="duration"
              >
                <h6 className="text-sm font-semibold">Reisedauer</h6>
              </div>
              <div className="w-full pt-5 lg:pt-0 space-y-3">

                <ul className="grid divide-y divide-action">
                  {options.map((i) => (
                    <li
                      key={i.label}
                      className={`transition-all duration-200 px-5 py-2 cursor-pointer ${value === i.value ? 'bg-primary text-action hover:bg-primary-600' : 'bg-gray-50 hover:bg-gray-200'}`}
                      onClick={() => onChange(i.value)}
                    >
                      {i.label}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex flex-row items-center justify-between w-full py-3 flex-nowrap">
                <Popover.Button
                  id="duration-apply-button"
                  className="w-full mx-3 border border-transparent rounded text-center appearance-none select-none bg-primary-500 text-white hover:text-white hover:bg-action-500 active:bg-action-500 text-sm px-4 h-10"
                >
                  Ok
                </Popover.Button>
              </div>
            </Dropdown>
          )}
        />
      </InputWrapper>
    </div>
  );
}
