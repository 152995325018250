import { Controller, useForm } from 'react-hook-form';
import { Popover } from '@headlessui/react';
import Dropdown from '../../elements/dropdown';
import InputWrapper from '../../globals/input-wrapper';

import TypeIcon from '../../../../public/assets/icons/anker/type.svg';

export default function BoatTypeSelect({ value, setValue, ...rest }) {
  const {
    control, watch,
  } = useForm({
    defaultValues: {
      boat_type_id: value,
    },
  });

  watch((v) => {
    setValue('boat_type_id', v.boat_type_id);

    // @todo workaround, close popover correctly
    document.getElementById('boat-type-apply-button').click();
  });

  const options = [
    {
      label: 'Alle Bootsarten',
      value: '',
    },
    {
      label: 'Segelyachten',
      value: 'in:1,8,15,20,16,31',
    },
    {
      label: 'Motoryachten',
      value: 'in:6,14,24,26,5,9,12,11,18,25,39',
    },
    {
      label: 'Katamarane',
      value: 'in:10,13,17',
    },
  /*
  {
    label: 'Tagesboote',
    value: 28,
  }, */
  ];

  const getLabelForValue = (value) => {
    const selected = options.find((item) => item.value === value);
    if (selected) {
      return selected.label;
    }
    return options[0].label;
  };

  return (
    <InputWrapper icon={(
      <TypeIcon
        className="absolute right-0 z-10 w-5 h-6 -mt-px pointer-events-none fill-action top-3 left-2"
      />
    )}
    >
      {' '}
      <Controller
        control={control}
        name="boat_type_id"
        render={({
          field: {
            onChange, value,
          },
        }) => (
          <Dropdown value={`${getLabelForValue(value)}`} {...rest}>
            <div
              className="flex flex-row items-center justify-between w-full px-5 pt-2 pb-1 border-b border-gray-100 lg:border-none"
            >
              <h6 className="text-sm font-semibold">Bootsart</h6>
            </div>
            <div className="w-full pt-5 lg:pt-0 space-y-3">

              <ul className="grid divide-y divide-action">
                {options.map((i) => (
                  <li
                    key={i.label}
                    className={`transition-all duration-200 px-5 py-2 cursor-pointer ${value === i.value ? 'bg-primary text-action hover:bg-primary-600' : 'bg-gray-50 hover:bg-gray-200'}`}
                    onClick={() => onChange(i.value)}
                  >
                    {i.label}
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex flex-row items-center justify-between w-full py-3 flex-nowrap">
              <Popover.Button
                id="boat-type-apply-button"
                className="w-full mx-3 border border-transparent rounded text-center appearance-none select-none bg-primary-500 text-white hover:text-white hover:bg-action-500 active:bg-action-500 text-sm px-4 h-10"
              >
                Ok
              </Popover.Button>
            </div>
          </Dropdown>
        )}
      />
    </InputWrapper>

  );
}
