import moment from 'moment';

export default function defaultDate() {
  const today = moment();
  let date;

  // month is zero indexed -> month: 3 represents april
  if (today.month() < 2) {
    date = moment({ month: 3, day: 1 });
  } else if (today.month() < 10) {
    date = moment().add(1, 'month');
  } else {
    date = moment({ year: today.year() + 1, month: 3, day: 1 });
  }

  return date.format('YYYY-MM-DD');
}
