import { Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

export default function Dropdown({
  icon, value, children, ...props
}) {
  return (
    <Popover {...props}>
      <Popover.Button
        className="relative flex flex-row w-full relative pl-10 py-3 pr-5 truncate bg-white border-gray-100 border text-left outline-0 ring-0 focus:border-primary focus:outline-0 focus:ring-0"
      >
        {icon}
        {' '}
        {value}
        <ChevronDownIcon className="absolute right-2 top-1/2 -translate-y-1/2 h-5 fill-gray-400" />
      </Popover.Button>
      <Popover.Panel
        className="z-[50] left-0 top-full w-full w-72 flex-col items-start flex justify-start text-primary-500 bg-white fixed absolute"

      >
        {children}
      </Popover.Panel>

    </Popover>
  );
}
