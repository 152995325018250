export default function EarlyBookingDiscount() {
  return (
    <div className="relative lg:mt-[5em]">
      <div className="xl:col-span-2 p-5 flex flex-col items-center justify-center text-white mb-10">
        <div className="bg-red-700 px-2.5 font-bold tracking-widest text-xl md:text-3xl uppercase">bis zu 20%</div>
        <div
          className="text-5xl lg:text-8xl mt-3 font-bold text-center tracking-tighter uppercase relative"
          id="text-early-booking"
        >
          Frühbucher
        </div>
        <div className="text-lg lg:text-3xl uppercase text-center">Auf ausgewählte Yachten</div>
        <img src="/assets/images/early-booking-banner/discount.svg" className="lg:absolute w-[100px] lg:w-[240px] md:right-[20px] lg:right-[-70px] lg:mt-[16em]" alt="Rabatt" />
      </div>
    </div>
  );
}
